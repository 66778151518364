
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["copy"]

  copy(e) {
    var copyText = this.copyTarget;
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");
    e.currentTarget.innerText = 'Copied!'
  }

  resellerCopy(event) {
      const button = event.currentTarget;
      const dataToCopy = button.dataset.clipboardData;

      // Create a temporary input element to copy the data to the clipboard
      const tempInput = document.createElement("input");
      tempInput.setAttribute("value", dataToCopy);

      // Append the input element to the DOM (note that it's not visible)
      document.body.appendChild(tempInput);

      // Select the text inside the input element
      tempInput.select();

      // Copy the selected text to the clipboard
      document.execCommand("copy");

      // Remove the temporary input element from the DOM
      document.body.removeChild(tempInput);
      event.currentTarget.innerText = 'Copied!'
      setTimeout(() => {
            button.innerText = "Copy Referral";
        }, 2000);
  }
}
