// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import MicroModal from 'micromodal';  // es6 module

export default class ModalController extends Controller {
  static targets = [ "output" ]

  connect() {
    MicroModal.init(
    {
      onClose: modal => document.dispatchEvent(new CustomEvent("shareUpdate", {})),
    });
  }
}
