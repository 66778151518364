// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "error" ]

  connect() {
  }

  errorHandler(event){
    const [data, status, xhr] = event.detail;
    try{
      this.errorTarget.innerHTML = JSON.parse(xhr.response).message;
    } catch(error) {
      this.errorTarget.innerHTML = data ;
    }
    this.errorTarget.scrollIntoView();
    grecaptcha.reset();

  }
}
