// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
// import "dwv-jqmobile/css/style.css";
// import "dwv-jqmobile/ext/jquery-mobile/jquery.mobile-1.4.5.min.css";
// import dwv from "dwv";
// import jQuery from "jquery";
// global.dwv = dwv;
// window.dwv = dwv;
// global.jQuery = jQuery;
// console.log(dwv);
// import "../custom/appgui";
// import "../custom/launcher"
// import "dwv-jqmobile/src/applauncher";

export default class extends Controller {
  static targets = [ "image" ]

  connect() {
    require('../custom/dicom/src/register-sw')
    // require('../custom/dicom/src/appgui')
    require('../custom/dicom/src/applauncher')
  }
}
