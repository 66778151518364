// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["error", 'examid', 'daterange', 'examinfo']

  connect() {
    if (this.hasExamidTarget) {
      this.autoHyphen();
    }
  }

  errorHandler(event) {
    const [data, status, xhr] = event.detail;
    this.errorTarget.innerHTML =
      data.message || JSON.parse(xhr.response).message;
    this.errorTarget.scrollIntoView();
  }

  successHandler(event) {
    const [data, status, xhr] = event.detail;
    const responseData = JSON.parse(xhr.response);
    this.examinfoTarget.innerHTML = "";
    let result = `<a href="${responseData.viewer_url}" target="_blank" class="w-full px-3 py-4 text-white bg-indigo-500 rounded-md mb-2 focus:bg-indigo-600 focus:outline-none"> Open in DICOM viewer (For desktop) </a>`
    if (responseData.jpeg_url) {
      result = result + `<a href="${responseData.jpeg_url}" target="_blank" class="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none mb-2"> View plain images (For mobile devices) </a>`
    }
    result = result + `<a href="${responseData.download_url}" class="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none mb-2" target="_blank"> Download DICOM Images (.zip) </a>`
    this.examinfoTarget.innerHTML = result;
  }

  autoHyphen(e) {
    var str = this.examidTarget.value;
    str = str.replaceAll("-", "");
    console.log(str);
    if (str.length > 4) {
      var parts = str.match(/.{1,4}/g);
      var new_value = parts.join("-");
      this.examidTarget.value = new_value;
    }
  }

  updateDateRange(e) {
    let filter = e.target.value;
    let startDate = new Date()
    let endDate = new Date()

    if (filter == 'Yesterday') {
      startDate = startDate.setDate(new Date().getDate() - 1);
      endDate = endDate.setDate(new Date().getDate());
    } else if (filter == 'Past Week') {
      startDate = startDate.setDate(new Date().getDate() - 7);
      endDate = endDate.setDate(new Date().getDate());
    } else if (filter == 'Past Month') {
      startDate = startDate.setDate(new Date().getDate() - 30);
      endDate = endDate.setDate(new Date().getDate());
    } else if (filter == 'Today') {
      startDate = startDate.setDate(new Date().getDate());
      endDate = endDate.setDate(new Date().getDate());
    }
    if (filter == 'Show All') {
      this.daterangeTarget._flatpickr.clear();
    } else {
      this.daterangeTarget._flatpickr.setDate([new Date(endDate).toLocaleDateString(), new Date(startDate).toLocaleDateString()])
    }
    this.daterangeTarget.closest('form').submit();
  }
}
