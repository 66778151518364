// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import * as cornerstone from "cornerstone-core";
import Hammer from 'hammerjs';
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneWADOImageLoader from "../custom/cornerstone/wadoImageLoader.min.js";
import * as dicomParser from 'dicom-parser/dist/dicomParser';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.external.Hammer = Hammer;

export default class PreviewController extends Controller {
  static targets = ["shareContainer", "spinner", "jpegUrl"]
  static values = { jpeg: String }
  connect() {
    cornerstoneTools.init();

    if (this.hasJpegUrlTarget) {
      this.loadJpegImages(this.jpegUrlTarget.value);
    }
  }

  showPreview(e) {
    var that = this;
    let study_id = e.currentTarget.getAttribute('data-study-id')
    let modal = e.currentTarget.getAttribute('data-modal')
    that.shareContainerTarget.innerHTML = "Loading...";
    let url = `/${modal}/${study_id}/preview`;
    console.log(that.spinnerTarget);
    Rails.ajax({
      type: "GET",
      url: url,
      success: function (data) {
        that.shareContainerTarget.innerHTML = "";
        data.forEach(element => {
          const div = document.createElement("DIV");
          let spinner = that.spinnerTarget.cloneNode(true);
          div.classList.add("relative", "justify-center", "flex");
          div.style.cssText = 'width:300px; height:300px';
          div.appendChild(spinner);
          console.log(div);
          console.log(spinner);
          let appendedChild = that.shareContainerTarget.appendChild(div);
          that.loadAndViewImage(element, appendedChild, false)
        });
      }
    })
  }

  loadJpegImages(url) {
    var that = this;
    that.shareContainerTarget.innerHTML = "Loading...";

    Rails.ajax({
      type: "GET",
      url: url,
      success: function (data) {
        that.shareContainerTarget.innerHTML = "";
        data.forEach(element => {
          const div = document.createElement("DIV");
          let spinner = that.spinnerTarget.cloneNode(true);
          div.classList.add("mb-2");
          div.style.cssText = `width:${element.columns}px; height:${element.rows}px; max-width: 2000px`;
          div.appendChild(spinner);
          let appendedChild = that.shareContainerTarget.appendChild(div);
          that.loadAndViewImage(element.image_url, appendedChild, true);
        });
        const MagnifyTool = cornerstoneTools.MagnifyTool;

        cornerstoneTools.addTool(MagnifyTool)
        cornerstoneTools.setToolActive('Magnify', { mouseButtonMask: 1 })

      }
    })
  }

  onImageRendered(e) {
    const eventData = e.detail;
    console.log(eventData);
    // eventData.viewport.displayedArea.brhc.x = eventData.image.width;
    // eventData.viewport.displayedArea.brhc.y = eventData.image.height;
    console.log(eventData.enabledElement);
    const divElement = eventData.enabledElement.element;
    const canvas = eventData.enabledElement.element.querySelector('canvas');
    const dataUrl = canvas.toDataURL();
    const newImg = document.createElement("img");
    newImg.src = dataUrl;
    divElement.parentNode.replaceChild(newImg, divElement);
  }

  loadAndViewImage(imageId, element, events = false) {
    let dicomimage = "wadouri:" + imageId;
    var that = this;
    try {
      cornerstone.loadAndCacheImage(dicomimage).then(function (image) {
        cornerstone.enable(element);
        if (events == true) {
          element.addEventListener('cornerstoneimagerendered', that.onImageRendered);
        }
        var viewport = cornerstone.getDefaultViewportForImage(element, image);
        console.log(viewport);
        console.log(cornerstone);
        element.querySelector("img").remove();
        if (events == true) {
          cornerstone.displayImage(element, image)
        } else {
          cornerstone.displayImage(element, image, viewport);
        }
      }, function (err) {
        alert(err);
      });
    }
    catch (err) {
      alert(err);
    }
  }
}
